import { Popup } from 'react-leaflet';

const MarkerPopup = ({ name,img }) => {
  return (
    <Popup>
      <div> <h1>{name}</h1>
      <img 
      style={{width:"100px"}}
      src={img}/>
      </div>
     
    </Popup>
  );
};

export default MarkerPopup;
