import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/homePage',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text',
  },
  {
    title: 'Nuevo Administrador',
    path: '/signupformAdmin',
    // icon: <IoIcons.IoIosPaper/>,
    icon: <AiIcons.AiFillRobot />,
    cName: 'nav-text',
  },
  {
    title: 'Usuarios',
    path: '/admin/usuarios',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text',
  },
  {
    title: 'Solicitudes',
    path: '/admin/formularios',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  },
  {
    title: 'Denuncias',
    path: '/admin/denuncias',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text',
  },
  {
    title: 'Soporte',
    path: '/soporte',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text',
  },
];
