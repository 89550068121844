import '../App.css';
// import {useHistory} from "react-router-dom";
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import Button from "@mui/material/Button";
import { TextField } from '@mui/material';
import axios from 'axios';
import { baseurl } from '../core';
import { GlobalContext } from '../context/Context';
import { useContext } from "react";
import Swal from 'sweetalert2';


const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),

  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});





function Loginform() {

  let history = useHistory();
  let { state, dispatch } = useContext(GlobalContext);



  const submit = (values) => {
    console.log("values", values)


    axios.post(`${baseurl}/api/v1/login`,
      {
        email: values.email,
        password: values.password
      }, {
      withCredentials: true
    })
      .then(res => {
        console.log(res.data);
        // alert('Inicio exitoso')
                Swal.fire({
              title: "Inicio correcto",
              text: "",
              icon: "warning",
              button: "Continuar",
              // showCloseButton: true,
              // showCancelButton: true,
              focusConfirm: false,
              // color: '#FFFFFF',
              width: 400,
              padding: '3em',
              //  background: '#fff url(/img/perro.gif)',
  //             backdrop: `
  //   rgba(0,0,123,0.4)
  //   url("/img/perro2.gif")
  //   center top 
  //   no-repeat
  // `
            });
      
        if (res.data.email) {
          dispatch({
            type: "USER_LOGIN",
            payload: {
              name: res.data.name,
              email: res.data.email,
              role:res.data.role,
              _id: res.data._id
            }
          })
        }


      }).catch((err) => {
        if (err.response && err.response.data && err.response.data.errorMessage) {
          Swal({
            text: err.response.data.errorMessage,
            icon: "error",
            type: "error"
          });
        }
      });



  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      email: '',
      password: '',

    },
    onSubmit: submit
  },
  );


  return (
    <>
    
      <div className="app-main1">
        <div className="main1">
          <form onSubmit={formik.handleSubmit}>

            <h1> Iniciar Sesión </h1>

            <TextField
              id="outlined-basic"
              name="email"
              label="Correo"
              className="inputbox"
              value={formik.values.email}
              onChange={formik.handleChange}

              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}


              variant="outlined" />

            <TextField
              id="outlined-basic"
              name="password"
              label="Contraseña"
              type="password"
              className="inputbox"

              value={formik.values.password}
              onChange={formik.handleChange}


              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}

              variant="outlined" />


            <Button  variant="contained" style={{width:"50%", left:"25%"}} className='LK' color="warning" type="submit">
              Iniciar
            </Button>


          </form>
           <a style={{color:"black"}} onClick={() => { history.push("/signup") }}>Si no tienes una cuenta <b>Regístrate</b></a>

        </div>
      </div>
    </>
  );
}

export default Loginform;