import { Carousel, Container } from "react-bootstrap";
import "../css/styles.css";
import "../js/scripts";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { baseurl } from "../core";
import { useFormik } from "formik";
import { Button, TextField } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";

export default function HomePage() {
  const submit = (values, { resetForm }) => {
    console.log("values", values);
    axios
      .post(
        `${baseurl}/api/v1/denuncia`,
        {
          nombres: values.nombres,
          apellido: values.apellido,
          cedula: values.cedula,
          tipo: values.tipo,
          lugar: values.lugar,
          descripcion: values.descripcion
          // tipo: currency,
        },{
          withCredentials: true,
        }
        
      )
      .then((res) => {
        Swal.fire({
          title: "Éxito",
          text: "Denuncia creada correctamente",
          // icon: "success",
          button: "Continuar",
          // showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          color: "#000000",
          width: 600,
          padding: "3em",
          background: "#fff url(/img/fondo2.jpg)",
          backdrop: `
        rgba(0,0,123,0.4)
        url("/img/perro.gif")
        left top
        no-repeat
      `,
        });
        console.log("postdata", res.data);
        resetForm({});
      });
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = yup.object({
    lugar: yup
      .string("Ingrese un lugar por favor")
      .min(6, "Ingrese un lugar válido")
      .required("Lugar no ingresado"),
    nombres: yup
      .string("Ingrese su nombre")
      .min(4, "Ingrese un nombre de usuario mínimo 4 caracteres")
      .required("Nombres es requerido"),
    apellido: yup
      .string("Ingrese sus apellidos")
      .min(8, "Ingrese dos apellido por favor")
      .required("Apellido es requerido"),
    descripcion: yup
      .string("Ingrese una descripción")
      .min(5, "")
      .required("Descripción es requerida"),
    cedula: yup
      .string("Ingrese una cedula válida")
      .matches(phoneRegExp, "Cédula es incorrecto")
      .min(10, "Ingrese un número correcto")
      .max(10, "Ingrese un número correcto")
      .required("Número de cédula es requerido"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      nombres: "",
      apellido: "",
      cedula: "",
      tipo: "",
      lugar: "",
      descripcion: ""
    },
    onSubmit: submit,
  });
  

  return (
    <>
      <br></br>
      <br></br>
     
      <section
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}
      >
        <h2 className="section-heading text-uppercase">
                DEJANOS TU DENUNCIA
              </h2>
         <Container fluid className=" max-height: ">
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100 "
              src="https://i0.wp.com/informacionecuador.com/wp-content/uploads/2021/08/denunciar-maltrato-animal-guayaquil-denuncia-en-l%C3%ADnea.jpg?fit=950%2C500&ssl=1"
              alt="First slide"
              style={{ height:"50%"}}
            />
            {/* <Carousel.Caption>
              <h3>¡Adóptame hoy!</h3>
              <p>
                Este es el momento para que encuentres un amigo de 4 patas.
                Ingresa a Adoptar y mira a nuestros pequeños amigos.
              </p>
            </Carousel.Caption> */}
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 h-10"
              src="/denuncia.png"
              alt="Second slide"
            />

            {/* <Carousel.Caption>
              <h3>¿Un nuevo miembro en la familia?</h3>
              <p>
                Adoptar una mascota es una enorma responsabilidad, ya que es un
                nuevo acompañante que podras tener a tu lado.
              </p>
            </Carousel.Caption> */}
          </Carousel.Item>
        </Carousel>
      </Container>
        <center>
          <div className="container">
            <div className="text-center">
              
              <h3 className="section-subheading text-muted" style={{padding:"2%"}}>
                Si tienes algo que decirnos, no dudes en dejar tu mensaje.
                Cualquier ayuda es buena para nuestra causa.
              </h3>
              <br></br>
              <br></br>
            </div>
            <form id="post-form" onSubmit={formik.handleSubmit}>
              <TextField
                id="outlined-basic"
                name="nombres"
                label="Nombres Completos"
                className="inputbox"
                value={formik.values.nombres}
                onChange={formik.handleChange}
                error={formik.touched.nombres && Boolean(formik.errors.nombres)}
                helperText={formik.touched.nombres && formik.errors.nombres}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="apellido"
                label="Apellidos"
                className="inputbox"
                value={formik.values.apellido}
                onChange={formik.handleChange}
                error={
                  formik.touched.apellido && Boolean(formik.errors.apellido)
                }
                helperText={formik.touched.apellido && formik.errors.apellido}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="cedula"
                label="Cédula"
                className="inputbox"
                value={formik.values.cedula}
                onChange={formik.handleChange}
                error={formik.touched.cedula && Boolean(formik.errors.cedula)}
                helperText={formik.touched.cedula && formik.errors.cedula}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="lugar"
                label="Lugar de Agreción"
                className="inputbox"
                value={formik.values.lugar}
                onChange={formik.handleChange}
                error={formik.touched.lugar && Boolean(formik.errors.lugar)}
                helperText={formik.touched.lugar && formik.errors.lugar}
                variant="outlined"
              />
              <TextField
              id="outlined-basic"
              name="tipo"
              label="Tipo de maltrato"
              className="inputbox"
              value={formik.values.tipo}
              onChange={formik.handleChange}
              error={formik.touched.tipo && Boolean(formik.errors.tipo)}
              helperText={formik.touched.tipo && formik.errors.tipo}
              variant="outlined"  
              />
              <TextField
                id="outlined-basic"
                name="descripcion"
                label="Escribe tu mensaje"
                type="descripcion"
                className="inputbox"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
                variant="outlined"
              />
              <center>
              <Button
                className="LK"
                variant="contained"
                color="warning"
                type="submit"
                style={{
                  width:"50%"
                }}
              >
                Enviar Denuncia
              </Button>
              </center>
            </form>
          </div>
        </center>
      </section>
    </>
  );
}
