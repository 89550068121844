import 'bootstrap/dist/css/bootstrap.min.css';

import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import axios from 'axios';
import { baseurl } from '../../core';
import { GlobalContext } from '../../context/Context';
import { useContext, useEffect } from 'react';

function DashNavbar() {
  let { state, dispatch } = useContext(GlobalContext);
  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log('res: ', res.data);

        if (res.data.email) {
          dispatch({
            type: 'USER_LOGIN',
            payload: {
              name: res.data.name,
              email: res.data.email,
              role: res.data.role,
              _id: res.data._id,
            },
          });
        } else {
          dispatch({ type: 'USER_LOGOUT' });
        }
      })
      .catch((e) => {
        dispatch({ type: 'USER_LOGOUT' });
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    axios
      .post(
        `${baseurl}/api/v1/logout`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log('res +++: ', res.data);
        dispatch({
          type: 'USER_LOGOUT',
        });
      });
  };

  return (
    <>
      {state.user.role === 'admin' ? (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
          <Container>
            <Navbar.Brand as={NavLink} to="/homePage" className="mt- 5">
              <Navbar bg="dark" variant="dark">
                <Container>
                  <img
                    alt=""
                    src="/img/huee.jpg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  />
                  MASCOTAME
                </Container>
              </Navbar>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <li>
                  <Link to="/" className="nav-itms">
                    Perfil
                  </Link>
                </li>
                <li>
                <NavDropdown title="Desaparecido" className='nav-items'>
              <NavDropdown.Item href="#">
                <Link to="/dashboard" className="nav-itms-dro">
                    Encuentrame
                  </Link></NavDropdown.Item>

              <NavDropdown.Item href="#action/3.2">
              <Link to="/mypost" className="nav-itms-dro">
                   Mis Publicaciones
                  </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
              <Link 
              className='nav-itms-dro'
              to={{
                pathname: '/map',
                state,
              }}
            >
              Ver perros desaparecidos
            </Link>
                </NavDropdown.Item>
              
            </NavDropdown>
                  
                </li>
                <li>
                  <Link to="/denuncias" className="nav-itms">
                    Denunciar
                  </Link>
                </li>
                <li>
                <NavDropdown title="Adopta" className='nav-items'>
              <NavDropdown.Item href="#">
              <Link as={NavLink} to="/adopta" className="nav-itms-dro">
                    Adopta
                  </Link>
                  </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <Link to="/myposts" className="nav-itms-dro">
                    Publicaciones
                  </Link>
              </NavDropdown.Item>
              
            </NavDropdown>
                 
                </li>
                <li>
                  <Link as={NavLink} to="/admin" className="nav-itms">
                    Admin
                  </Link>
                </li>
                {/* <li>
                        <Link as={NavLink} to="/formulario" className="nav-itms">Form</Link>
                    </li> */}
                <li>
                  <Link
                    to="/api/v1/logout"
                    onClick={logout}
                    className="nav-itms"
                    style={{ fontWeight: '600' }}
                  >
                    Cerrar Sesión
                  </Link>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
          <Container>
            <Navbar.Brand as={NavLink} to="/homePage" className="mt- 5">
              <Navbar bg="dark" variant="dark">
                <Container>
                  <img
                    alt=""
                    src="/img/huee.jpg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  />
                  MASCOTAME
                </Container>
              </Navbar>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <li>
                  <Link to="/" className="nav-itms">
                    Perfil
                  </Link>
                </li>

                <li>
                <NavDropdown title="Desaparecido" className='nav-items'>
              <NavDropdown.Item href="#">
                <Link to="/dashboard" className="nav-itms-dro">
                    Encuentrame
                  </Link></NavDropdown.Item>

              <NavDropdown.Item href="#action/3.2">
              <Link to="/mypost" className="nav-itms-dro">
                    Publicaciones
                  </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
              <Link 
              className='nav-itms-dro'
              to={{
                pathname: '/map',
                state,
              }}
            >
              Ver perros desaparecidos
            </Link>
                </NavDropdown.Item>
              
            </NavDropdown>
                  
                </li>

                {/* <li>
                    <Link to="/myposts" className="nav-itms">Publicaciones</Link>
                </li> */}
                 <li>
                  <Link to="/denuncias" className="nav-itms">
                    Denunciar
                   </Link>
                </li>
                <li>
                <NavDropdown title="Adopta" className='nav-items'>
              <NavDropdown.Item href="#">
              <Link as={NavLink} to="/adopta" className="nav-itms-dro">
                    Adopta
                  </Link>
                  </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <Link to="/myposts" className="nav-itms-dro">
                    Publicaciones
                  </Link>
              </NavDropdown.Item>
              
            </NavDropdown>
                </li>

                {/* <li>
                    <Link as={NavLink} to="/formulario" className="nav-itms">Form</Link>
                </li> */}
                <li>
                  <Link
                    to="/login"
                    onClick={logout}
                    className="nav-itms"
                    style={{ fontWeight: '600' }}
                  >
                    Cerrar Sesión
                  </Link>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
}

export default DashNavbar;
