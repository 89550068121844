import axios from 'axios';
import { useEffect, useState } from 'react';
import { baseurl } from '../../core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import swal from 'sweetalert';
import Navbar from "./../Navbar";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Formularios({ isTitle = true }) {
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = () => {
    axios
      .get(`${baseurl}/api/v1/formulario`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setPosts(response.data);
        setFilteredPosts(response.data);
      })
      .catch((err) => alert('Error in getting data'));
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value === '') {
      setFilteredPosts(posts);
    } else {
      const filteredPosts = posts.filter((post) => {
        return post.nombrescompletos
          ?.toLowerCase()
          .includes(search.toLowerCase());
      });

      setFilteredPosts(filteredPosts);
    }
  };

  const handlePutChangeStatus = async (item) => {
    console.log('item: ', item);

    const estado = item.estado === 'aprobado' ? 'noaprobado' : 'aprobado';

    await axios
      .put(
        `${baseurl}/api/v1/formularioChangeStatus/${item._id}`,
        {
          estado,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        handleGetData();
      })
      .catch((err) => alert('Error in getting data'));
  };

  async function exportDataToPdf() {
    var docDef = {
      pageSize: 'A2',

      //pageOrientation: 'landscape',

      defaultStyle: {
        fontSize: 8,
        bold: false,
        border: 1,
      },

      content: [
        // margin: [left, top, right, bottom]
        {
          text: 'Lista de Solicitudes',
          alignment: 'center',
          bold: true,
          fontSize: '14',
          margin: [0, 0, 0, 0],
        },

        // {
        //     image: await this.getBase64ImageFromURL("http://localhost:3000/images/employees.png")
        //  },

        {
          layout: 'lightHorizontalLines',

          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],

            body: [
              [
                { text: 'Cédula', bold: true },
                { text: 'Nombres', bold: true },
                { text: 'Correo', bold: true },
                { text: 'Fecha', bold: true },
                { text: 'Teléfono', bold: true },
                { text: 'Dirección', bold: true },
                { text: 'Ocupación', bold: true },
                { text: 'Mensual', bold: true },
                { text: 'Estado Civil', bold: true },
                { text: 'Estado', bold: true },
              ],
              ...posts.map((post) => [
                post.cedula,
                post.nombrescompletos,

                post.email,
                post.time,
                '+593' + post.phone,
                post.direccion,
                post.trabajo,
                '$' + post.ingreso,
                post.estadoCivil,

                post.estado,
              ]),
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDef).download();
  }

  // function getBase64ImageFromURL(url) {
  //     return new Promise((resolve, reject) => {
  //         var img = new Image();
  //         img.setAttribute("crossOrigin", "anonymous");

  //         img.onload = () => {
  //             var canvas = document.createElement("canvas");
  //             canvas.width = img.width;
  //             canvas.height = img.height;

  //             var ctx = canvas.getContext("2d");
  //             ctx.drawImage(img, 30, 30, 500, 500);

  //             var dataURL = canvas.toDataURL("image/png");

  //             resolve(dataURL);
  //         };

  //         img.onerror = error => {
  //             reject(error);
  //         };

  //         img.src = url;
  //     });
  // }
  const submit = (values, { resetForm }) => {
    console.log('values', values);
    axios
      .get(`${baseurl}/api/v1/formulario`, {
        nombrescompletos: values.nombrescompletos,
      })
      .then((res) => {
        console.log(res.data);
        alert('Usuario Encontrado');
        resetForm({});
      })
      .catch((err) => {
        swal({
          text: err.response.data.errorMessage,
          icon: 'error',
          type: 'error',
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      nombrescompletos: '',
    },
    onSubmit: submit,
  });

  return (
    <>
      {isTitle && (
        <>
          <br></br>
          <br></br>
          <br></br>
          <h1>Formularios</h1>
        </>
      )}
      <Navbar />

      <main role="main" className="container">
        <h2 className="text-center mt-5 mb-3">Lista de Solicitudes</h2>
        <div className="text-center">
          <TextField
            id="standard-basic"
            type="search"
            autoComplete="off"
            name="nombrescompletos"
            value={search}
            onChange={handleChangeSearch}
            onBlur={formik.handleBlur}
            placeholder="Buscar por nombre"
            required
          />
          <br></br>

          <button
            type="button"
            className="mb-3 btn"
            style={{
              backgroundColor: '#00a8ff',
              color: 'white',
            }}
            onClick={exportDataToPdf}
          >
            Exportar PDF
          </button>
        </div>
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Cédula</th>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Fecha</th>
              <th>Teléfono</th>
              <th>Dirección</th>
              <th>Ocupación</th>
              <th>Salario</th>
              <th>Estado Civil</th>
              <th>Estado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredPosts.map((post) => {
              return (
                <tr key={post?._id}>
                  <td>{post?.cedula}</td>
                  <td>{post?.nombrescompletos}</td>
                  <td>{post?.email}</td>
                  <td>{post?.time}</td>
                  <td>+593{post?.phone}</td>
                  <td>{post?.direccion}</td>
                  <td>{post?.trabajo}</td>
                  <td>$ {post?.ingreso}</td>
                  <td>{post?.estadoCivil}</td>
                  <td>
                    {post?.estado === 'noaprobado' ? 'No Aprobado' : 'Aprobado'}
                  </td>
                  <td>
                    <button
                      style={{
                        padding: '5px',
                        borderRadius: '10px',
                        borderColor: '#00a8ff',
                        color: '#00a8ff',
                      }}
                      onClick={() => handlePutChangeStatus(post)}
                    >
                      Cambiar Estado
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    </>
  );
}
