import "../../App.css";
import React, { useEffect, useState } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Button} from "react-bootstrap";
import uniquid from "uniqid";
import { useFormik } from "formik";
import * as yup from "yup";
import { MenuItem, TextField } from "@mui/material";
import axios from "axios";
import { baseurl } from "../../core";
import moment from "moment";
import io from "socket.io-client";
import Swal from "sweetalert2";
import CardPro from "./CardPro";
import { MyLocationIcon } from "./VenueLocationIcon";

const validationSchema = yup.object({
  post: yup
    .string("Enter your password")
    .min(4, "Name should be of minimum 4 characters length")
    .required("Name is required"),
});

function Dashboard() {
  const [posts, setPosts] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [state, setState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 15,
  });
  const [imageData, setImageData] = useState(null);

  const [currentLocation, setCurrentLocation] = useState({
    lat: -1.6600336246413998,
    lng: -78.67974758148195,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const { latitude, longitude } = position.coords;

        setCurrentLocation({ lat: latitude, lng: longitude });

        formik.values.location = latitude + ", " + longitude;
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      },
      {
        enableHighAccuracy: true,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (values, { resetForm }) => {
    console.log("values", values);
    let m = moment().format("MMMM Do YYYY");
    console.log(m);
    axios
      .post(
        `${baseurl}/api/v1/postdes`,
        {
          idPostDes: uniquid(),
          post: values.post,
          raza: values.raza,
          edad: values.edad,
          tamano: currency,
          color: values.color,
          recompensa:values.recompensa,
          location: values.location,
          descripcion: values.descripcion,
          imgUrl: imageData,
          time: m,
          tel:values.tel
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        Swal.fire({
          title: "Éxito",
          text: "Anuncio creado correctamente",
          // icon: "success",
          button: "Continuar",
          // showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          color: "#000000",
          width: 600,
          padding: "3em",
          background: "#fff url(/img/fondo2.jpg)",
          backdrop: `
    rgba(0,0,123,0.4)
    url("/img/perro.gif")
    left top
    no-repeat
  `,
        });
        console.log("postdata", res.data);
        resetForm({});
      });
  };

  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/postdes?page=0`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setPosts(response.data);
      })
      .catch((err) => alert("Error in getting data"));
  }, []);

  useEffect(() => {
    const socket = io(baseurl);

    socket.on("connect", function () {
      console.log("connected to server");
    });

    socket.on("disconnect", function (message) {
      console.log("disconnected from server: ", message);
    });

    socket.on("POSTSDES", function (data) {
      console.log(data);
      setPosts((prev) => [data, ...prev]);
    });

    return () => {
      socket.close();
    };
  }, []);

  const currencies = [
    {
      value: "Grande",
      label: "Grande",
    },
    {
      value: "Mediano",
      label: "Mediano",
    },
    {
      value: "Pequeño",
      label: "Pequeño",
    },
  ];

  const [currency, setCurrency] = React.useState("undefined");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const loadMore = () => {
    axios
      .get(`${baseurl}/api/v1/postdes?page=${posts.length}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log("res +++: ", res.data);
        if (res.data?.length) {
          const newPosts = [...posts, ...res.data];
          setPosts(newPosts);
        } else {
          setIsMore(false);
        }
      });
  };

  const [file, setFile] = useState(null);

  const sendHandler = () => {
    if (!file) {
      alert("you must upload file");
      return;
    }

    const formdata = new FormData();
    formdata.append("image", file);

    fetch("http:// localhost:9000/images/post", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .catch((err) => {
        console.error(err);
      });

    document.getElementById("fileinput").value = null;

    setFile(null);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      post: "",
      raza: "",
      edad: "",
      tamano: "",
      color: "",
      recompensa: "",
      location: "",
      descripcion: "",
      tel:""
    },
    onSubmit: submit,
  });

  const handleChangeImage = (e) => {
    const file = e.target.files[0] || e.dataTransfer.files[0];
    if (!file) return;
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      alert("El archivo debe ser una imagen");
      return;
    }
    if (file.size > 5000000) {
      alert("El archivo debe ser menor a 5MB");
      return;
    }
    const reader = new FileReader();
    reader.onload = (er) => {
      setImageData(er.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <br></br>
      <br></br>

      <section
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}
      >
        <h1
          style={{ padding: "2%" }}
          className="section-heading text-uppercase"
        >
          Selecciona el lugar de perdida
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            itemAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Map
            center={currentLocation}
            zoom={state.zoom}
            selectArea={(e) => {
              console.log(e);
            }}
            style={{
              width: "600px",
              height: "400px",
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* select location position */}
            <Marker
              position={currentLocation}
              icon={MyLocationIcon}
              draggable={true}
              onDragend={(e) => {
                console.log(e.target.getLatLng());
                setCurrentLocation(e.target.getLatLng());

                formik.setFieldValue(
                  "location",
                  e.target.getLatLng().lat + ", " + e.target.getLatLng().lng
                );
              }}
            />
          </Map>
        </div>
        <h3 className="section-heading text-uppercase">
          Su ubicación actual es:
        </h3>
        <br></br>
        <center className="section-heading text-uppercase">
          <p>
            <b>Latitude:</b> {currentLocation.lat}
          </p>
          <p>
            <b>Longitude:</b> {currentLocation.lng}
          </p>
        </center>

        <div className="" style={{marginRight:"6%",}}>
          <div className="post-main">
            <form id="post-form"  style={{ marginLeft:"%"}} onSubmit={formik.handleSubmit}>
              <h3 style={{ padding: "1%" }}>
                Pública tu mascota desaparecida
              </h3>
              <br></br>
              <TextField
                id="outlined-basic"
                // startAdornment={
                //   <InputAdornment position="start">
                //     <AccountCircle />
                //   </InputAdornment>
                // }
                name="post"
                style={{ padding: "1%",width:"40%",marginLeft:"30%"}}
                label="Nombre del perro"
                type="post"
                className="box"
                value={formik.values.post}
                onChange={formik.handleChange}
                error={formik.touched.post && Boolean(formik.errors.post)}
                helperText={formik.touched.post && formik.errors.post}
                variant="outlined"
              />

              <TextField
                id="outlined-basic"
                name="raza"
                label="Raza"
                type="raza"
                className="box"
                value={formik.values.raza}
                onChange={formik.handleChange}
                style={{ padding: "1%" ,width:"40%",marginLeft:"30%", display:"flex"}}
                error={formik.touched.raza && Boolean(formik.errors.raza)}
                helperText={formik.touched.raza && formik.errors.raza}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="edad"
                label="Edad"
                type="edad"
                className="box"
                style={{ padding: "1%",width:"40%",marginLeft:"30%" }}
                value={formik.values.edad}
                onChange={formik.handleChange}
                error={formik.touched.edad && Boolean(formik.errors.edad)}
                helperText={formik.touched.edad && formik.errors.edad}
                variant="outlined"
              />

              <TextField
                id="outlined-basic"
                name="tamano"
                select
                style={{ padding: "1%",width:"40%",marginLeft:"30%"}}
                label="Tamaño"
                value={currency}
                onChange={handleChange}
                className="box"
                helperText="Seleccione un tamaño porfavor"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* error={formik.touched.tamano && Boolean(formik.errors.tamano)}
                            helperText={formik.touched.tamano && formik.errors.tamano}
                            
                            variant="outlined" /> */}
              <TextField
                id="outlined-basic"
                name="color"
                label="Color"
                type="colore"
                style={{ padding: "1%",width:"40%",marginLeft:"30%" }}
                className="box"
                value={formik.values.color}
                onChange={formik.handleChange}
                error={formik.touched.color && Boolean(formik.errors.color)}
                helperText={formik.touched.color && formik.errors.color}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="recompensa"
                label="Recompensa"
                type="money"
                style={{ padding: "1%",width:"40%",marginLeft:"30%" }}
                className="box"
                value={formik.values.recompensa}
                onChange={formik.handleChange}
                error={formik.touched.color && Boolean(formik.errors.color)}
                helperText={formik.touched.color && formik.errors.color}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="location"
                label="Lugar de perdida"
                type="location"
                className="box"
                value={formik.values.location}
                onChange={formik.handleChange}
                style={{ padding: "1%" ,width:"40%",marginLeft:"30%"}}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
               variant="outlined"
                helperText="En el mapa de arriba seleccione la ubicación"
              />

              <TextField
                id="outlined-basic"
                name="descripcion"
                style={{ padding: "1%",width:"40%",marginLeft:"30%" }}
                label="Descripción"
                type="descripcion"
                className="box"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
                variant="outlined"
              />

              <div>
                <img
                  style={{
                    width: "150px",
                  }}
                  src={
                    imageData
                      ? imageData
                      : "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
                  }
                  alt="Imagen"
                />
              </div>

              <div className="container mt-5">
                <div className="cardp-3">
                  <div className="row">
                    <div className="col-10">
                      <input
                        id="fileinput"
                        onChange={handleChangeImage}
                        className="form-control"
                        type="file"
                      ></input>
                    </div>
                    <div className="col-2">
                      <button
                        onClick={sendHandler}
                        type="button"
                        className="btn btn-primary col-12"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                id="btn"
                bg="dark"
                type="submit"
                className="LK"
                action={formik.resetForm}
              >
                Publicar
              </Button>
            </form>
          </div>
          <h3
            className="section-heading text-uppercase"
            style={{ marginTop: "3%" }}
          >
            Mascotas Desaparecidas
          </h3>
          <h6 className="h6" style={{ color: "orange" }}>
            Ayudanos a encontrarlos
          </h6>
          <div id="posts">
            {posts?.map((post, index) => (
              <div className="postcard" key={index}>
                <CardPro
                  key={index}
                  idPostDes={post?.idPostDes}
                  id={post?._id}
                  name={post?.name}
                  time={post?.time}
                  post={post?.post}
                  edad={post?.edad}
                  raza={post?.raza}
                  tamano={post?.tamano}
                  recompensa={post?.recompensa}
                  location={post?.location}
                  color={post?.color}
                  descripcion={post?.descripcion}
                  email={post?.email}
                  phone={post?.phone}
                  imgUrl={post?.imgUrl}
                />
              </div>
            ))}
            {isMore ? (
              <Button
                style={{ backgroundColor: "aqua" }}
                className=""
                onClick={loadMore}
              >
                {" "}
                &#x2193; Load More
              </Button>
            ) : (
              <p id="nopost">No More Posts</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
