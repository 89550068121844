import React from 'react';
import { Marker } from 'react-leaflet';
import MarkerPopup from './MarkerPopup';

import { VenueLocationIcon } from './VenueLocationIcon';

const VenueMarkers = ({ venues }) => {
  return (
    <>
      {venues.map((venue, i) => (
        <Marker key={i} position={venue.geometry} icon={VenueLocationIcon}>
          <MarkerPopup name={venue.name} />
          <MarkerPopup img={venue.img} />
        </Marker>
      ))}
    </>
  );
};

export default VenueMarkers;
