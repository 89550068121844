import "../../App.css";
import React, { useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";
import { Carousel, Container } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { MenuItem, TextField } from "@mui/material";
import Postcards from "./Postcards";
import axios from "axios";
import { baseurl } from "../../core";
import { GlobalContext } from "../../context/Context";
import { useContext } from "react";
import moment from "moment";
import io from "socket.io-client";
import Swal from "sweetalert2";

const validationSchema = yup.object({
  post: yup
    .string("Enter your password")
    .min(4, "Name should be of minimum 4 characters length")
    .required("Name is required"),
});

function Dashboard() {
  let { state, dispatch } = useContext(GlobalContext);
  const [posts, setPosts] = useState([]);
  const [isMore, setIsMore] = useState(true);

  const [imageData, setImageData] = useState(null);

  const submit = (values, { resetForm }) => {
    console.log("values", values);
    let m = moment().format("MMMM Do YYYY");
    console.log(m);
    
    axios
      .post(
        `${baseurl}/api/v1/post`,
        {
          post: values.post,
          raza: values.raza,
          edad: values.edad,
          tamano: currency,
          vacunas: values.vacunas,
          color: values.color,
          este: values.este,
          descripcion: values.descripcion,
          imgUrl: imageData,
          time: m,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
          Swal.fire({
            title: "Éxito",
            text: "Anuncio creado correctamente",
            // icon: "success",
            button: "Continuar",
            // showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            color: "#000000",
            width: 600,
            padding: "3em",
            background: "#fff url(/img/fondo2.jpg)",
            backdrop: `
      rgba(0,0,123,0.4)
      url("/img/perro.gif")
      left top
      no-repeat
    `,
          });
        console.log("postdata", res.data);
        resetForm({});
      });
  };

  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/post?page=0`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setPosts(response.data);
      })
      .catch((err) => alert("Error in getting data"));
  }, []);

  useEffect(() => {
    const socket = io(baseurl);
    // to connect with locally running Socker.io server

    socket.on("connect", function () {
      console.log("connected to server");
    });

    socket.on("disconnect", function (message) {
      console.log("disconnected from server: ", message);
    });

    socket.on("POSTS", function (data) {
      console.log(data);
      setPosts((prev) => [data, ...prev]);
    });

    return () => {
      socket.close();
    };
  }, []);

  const currencies = [
    {
      value: "Grande",
      label: "Grande",
    },
    {
      value: "Mediano",
      label: "Mediano",
    },
    {
      value: "Pequeño",
      label: "Pequeño",
    },
  ];
  const loadMore = () => {
    axios
      .get(`${baseurl}/api/v1/post?page=${posts.length}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log("res +++: ", res.data);
        if (res.data?.length) {
          const newPosts = [...posts, ...res.data];
          setPosts(newPosts);
        } else {
          setIsMore(false);
        }
      });
  };

  const [file, setFile] = useState(null);

 

  const sendHandler = () => {
    if (!file) {
      alert("you must upload file");
      return;
    }

    const formdata = new FormData();
    formdata.append("image", file);

    fetch("http:// localhost:9000/images/post", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .catch((err) => {
        console.error(err);
      });

    document.getElementById("fileinput").value = null;

    setFile(null);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      post: "",
      raza: "",
      edad: "",
      tamano: "",
      vacunas: "",
      color: "",
      este: "",
      descripcion: "",
    },
    onSubmit: submit,
  });

  const handleChangeImage = (e) => {
    const file = e.target.files[0] || e.dataTransfer.files[0];
    if (!file) return;
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      alert("El archivo debe ser una imagen");
      return;
    }
    if (file.size > 5000000) {
      alert("El archivo debe ser menor a 5MB");
      return;
    }
    const reader = new FileReader();
    reader.onload = (er) => {
      setImageData(er.target.result);
    };
    reader.readAsDataURL(file);
  };
  const [currency, setCurrency] = React.useState("undefined");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  return (
    <>      

<div className=""
style={{
  
}}>

</div>
      <section
      
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}
      >
        <h2 className="Font_color"
            style={{ marginTop: "0%",
            padding:"2%"
            }}
>¡No compres uno de raza, adopta uno sin casa! </h2>
              <Container fluid className=" max-height: ">
        <Carousel interval={9000}>
          <Carousel.Item>
            <img
              className="d-block w-100 h1"
              src="/adoptame.jpg"
              alt="First slide"
              style={{ height:"50%"}}
            />
            <Carousel.Caption>
              <h3>¡Adóptame hoy!</h3>
              <p>
                Este es el momento para que encuentres un amigo de 4 patas.
                Ingresa a Adoptar y mira a nuestros pequeños amigos.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
            
              className="d-block w-100 h1"
              src="/adopta.png"
              alt="Second slide"
            />

            
          </Carousel.Item>
        </Carousel>
      </Container>
        <div className=""style={{marginRight:"8%",
      }}>
          <div className="post-main">
            <form id="post-form" style={{ marginLeft:"%"}} onSubmit={formik.handleSubmit}>
              <h3 style={{ padding: "4%" }}>
                Pública tu mascota para dar en adopción
              </h3>
              <center>
              <TextField
                id="outlined-basic"
                name="post"
                label="Nombre del perro"
                type="post"
                className="box"
                value={formik.values.post}
                onChange={formik.handleChange}
                style={{ padding: "1%"}}
                error={formik.touched.post && Boolean(formik.errors.post)}
                helperText={formik.touched.post && formik.errors.post}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="raza"
                label="Raza"
                type="raza"
                className="box"
                value={formik.values.raza}
                onChange={formik.handleChange}
                style={{ padding: "1%" }}
                error={formik.touched.raza && Boolean(formik.errors.raza)}
                helperText={formik.touched.raza && formik.errors.raza}
                variant="outlined"
                />
              <TextField
                id="outlined-basic"
                name="edad"
                label="Edad"
                type="edad"
                className="box"
                style={{ padding: "1%" }}
                value={formik.values.edad}
                onChange={formik.handleChange}
                error={formik.touched.edad && Boolean(formik.errors.edad)}
                helperText={formik.touched.edad && formik.errors.edad}
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="tamano"
                select
                style={{ padding: "1%" }}
                label="Tamaño"
                value={currency}
                onChange={handleChange}
                className="box"
                helperText="Seleccione un tamaño porfavor"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* error={formik.touched.tamano && Boolean(formik.errors.tamano)}
                            helperText={formik.touched.tamano && formik.errors.tamano}
                            
                          variant="outlined" /> */}
              <TextField
                id="outlined-basic"
                name="vacunas"
                label="Vacunas"
                style={{ padding: "1%" }}
                type="autofocus"
                className="box"
                value={formik.values.vacunas}
                onChange={formik.handleChange}
                error={formik.touched.vacunas && Boolean(formik.errors.vacunas)}
                helperText={formik.touched.vacunas && formik.errors.vacunas}
                variant="outlined"
                />
              <TextField
                id="outlined-basic"
                name="color"
                label="Color"
                type="colore"
                style={{ padding: "1%" }}
                className="box"
                value={formik.values.color}
                onChange={formik.handleChange}
                error={formik.touched.color && Boolean(formik.errors.color)}
                helperText={formik.touched.color && formik.errors.color}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                name="este"
                label="Esterelización"
                type="bool"
                className="box"
                value={formik.values.este}
                onChange={formik.handleChange}
                style={{ padding: "1%" }}
                error={formik.touched.este && Boolean(formik.errors.este)}
                helperText={formik.touched.este && formik.errors.este}
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="descripcion"
                style={{ padding: "1%" }}
                label="Descripción"
                type="descripcion"
                className="box"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
                variant="outlined"
                />

              <div className="container mt-5">
                <div >
                  <img
                    style={{
                      width: "150px",
                    }}
                    src={
                      imageData
                      ? imageData
                      : "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
                    }
                    alt="Imagen"
                    />
                </div>

                <div className="c">
                  <div className="row">
                    <div className="col-10">
                      <input
                        id="fileinput"
                        width="70%"
                        onChange={handleChangeImage}
                        className="form-control"
                        type="file"
                        ></input>
                    </div>
                    <div className="col-2">
                      <button
                        onClick={sendHandler}
                        type="button"
                        className="btn btn-primary col-12"
                        >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <center>
                <Button
                  className="LK"
                  color="warning"
                  type="submit"
                  action={formik.resetForm}
                  >
                  Publicar
                </Button>
              </center>
                  </center>
            </form>
          </div>
          <br></br>
          <br></br>
          <center  style={{ padding: "4%",
              marginLeft:"10%" }}>
            <h3
              className="section-heading text-uppercase"
              style={{ padding:"1%",
              marginLeft:"10%" }}
            >
              Mascotas que puedes adoptar
            </h3>
            <h3 className="section-subheading text-muted">
               Estos amiguitos esperan por tí.
              </h3>
          </center>
          <div style={{ marginLeft:"3%"}}>
            {posts?.map((posts, index) => (
              // <div id="cont">
              //     <h3 id="post-name">{posts?.name}</h3>
              //     <hr />
              //     <p id="post-item">{posts?.post}</p>
              //     <p className="buttonbox">
              //         <button className="btn">Like</button>
              //         <button className="btn">Comment</button>
              //         <button className="btn">Share</button>
              //     </p>
              // </div>
              <div className="postcard">
                <Postcards
                  key={index}
                  _id={posts?._id}
                  name={posts?.name}
                  time={posts?.time}
                  post={posts?.post}
                  edad={posts?.edad}
                  raza={posts?.raza}
                  tamano={posts?.tamano}
                  vacunas={posts?.vacunas}
                  color={posts?.color}
                  este={posts?.este}
                  descripcion={posts?.descripcion}
                  email={posts?.email}
                  imgUrl={posts?.imgUrl}
                />
              </div>
            ))}
            {isMore ? (
              <Button className="LL" onClick={loadMore}>
                {" "}
                &#x2193; Load More
              </Button>
            ) : (
              <p id="nopost">No More Posts</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
