import "../App.css";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import axios from "axios";
import { baseurl } from "../core";
import { GlobalContext } from "../context/Context";
import { useContext } from "react";
import moment from "moment";
import io from "socket.io-client";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  nombrescompletos: yup
    .string("Enter your name")
    .min(4, "Name should be of minimum 4 characters length")
    .required("Name is required"),
  cedula: yup
    .number("Enter your password")
    .min(10, "Ingrese su cedula valida")
    .required("Cedula es requerida"),
  edad: yup.number("Ingrese números").min(1).required("Ingrese su edad"),
  phone: yup
    .string("Enter your phone no.")
    .matches(phoneRegExp, "Número de teléfono es incorrecto")
    .min(11, "Ingrese un número correcto")
    .max(11, "Ingrese un número correcto")
    .required("Número de teléfono requerido"),
  direccion: yup
    .string("Ingrese una dirección correcta")
    .min(9)
    .required("Ingrese su dirección"),
  trabajo: yup
    .string("Ingrese su ocupación")
    .min(2)
    .required("Ingrese su ocupación"),
  ingreso: yup
    .number("Ingrese su mensual")
    .min(3)
    .required("Ingrese su mensual"),
  estadoCivil: yup
    .string("Ingrese un estado civil")
    .min(4)
    .required("Ingrese su estado civil"),
  familiaN: yup
    .number("Ingrese un número")
    .required("Ingrese un número"),
});

function Formulario() {
  // let { state, dispatch } = useContext(GlobalContext);
  // const [posts, setPosts] = useState([])
  // let history = useHistory();

  const params = useParams();

  const [item, setItem] = useState("");
  useEffect(() => {
    let id = params._id;
    axios
      .get(`${baseurl}/api/v1/mypost/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        const dataUsuario = res.data;
        console.log(dataUsuario);
        setItem(res.data);
      });
  }, []);

  const submit = (values, { resetForm }) => {
    console.log("values", values);
    let m = moment().format("MMMM Do YYYY");
    console.log(m);
    axios
      .post(
        `${baseurl}/api/v1/formulario`,
        {
          nombrescompletos: values.nombrescompletos,
          cedula: values.cedula,
          edad: values.edad,
          phone: values.phone,
          direccion: values.direccion,
          trabajo: values.trabajo,
          ingreso: values.ingreso,
          estadoCivil: values.estadoCivil,
          familiaN: values.familiaN,
          time: m,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        Swal.fire({
          title: "Éxito",
          text: "Adopción creada correctamente",
          // icon: "success",
          button: "Continuar",
          // showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          color: "#000000",
          width: 600,
          padding: "3em",
          background: "#fff url(/img/fondo2.jpg)",
          backdrop: `
              rgba(0,0,123,0.4)
              url("/img/perro.gif")
              left top
              no-repeat
            `,
        });

        console.log("postdata", res.data);
        resetForm({});
      });
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      nombrescompletos: "",
      cedula: "",
      edad: "",
      phone: "",
      direccion: "",
      trabajo: "",
      ingreso: "",
      estadoCivil: "",
      familiaN: "",
      estado: "",
    },
    onSubmit: submit,
  });

  return (
    <>
      <br></br>

      <br></br>

      {/* <ButtonGroup className='mt-3 mx-3 '>
  
  <DropdownButton variant='dark' as={ButtonGroup} title="Mi actividad" id="bg-nested-dropdown">
    <Dropdown.Item eventKey="1" as={Link} to="/myposts">Publicaciones</Dropdown.Item>
    {/* <Dropdown.Item eventKey="2">Buscar</Dropdown.Item> */}
      {/* </DropdownButton>
</ButtonGroup> */}
      <section
        className="page-section"
        id="contact"
        style={{
            textAlign: "center",
        }}
        >
        <div className=""style={{marginRight:"1%"}}>
          <div className="post-main">
            <form id="post-form" onSubmit={formik.handleSubmit}>
              <img
                style={{
                    padding: "2%",
                    width: "200px",
                    float: "right",
                    display: "flex",
                }}
                src="/mascotame.png"
                />

              <h3 style={{ padding: "0%" }}> REGISTRO PARA ADOPTAR A: </h3>
              <h2>
                <u>{item.post}</u>
              </h2>
              {
                  <div>
                  <img
                    style={{
                        padding: "3%",
                        width: "400px",
                        verticalAlign: "center",
                    }}
                    src={
                        item.imgUrl
                        ? item.imgUrl
                        : "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
                    }
                    alt="Imagen"
                    />
                </div>
              }

              <TextField
                id="outlined-basic"
                name="nombrescompletos"
                label="Escriba sus nombres completos "
                type="nombrescompletos"
                className="inputbox"
                value={formik.values.nombrescompletos}
                onChange={formik.handleChange}
                error={
                    formik.touched.nombrescompletos &&
                    Boolean(formik.errors.nombrescompletos)
                }
                helperText={
                    formik.touched.nombrescompletos &&
                    formik.errors.nombrescompletos
                }
                variant="outlined"
                />
              <TextField
                id="outlined-basic"
                name="cedula"
                label="Escriba su cedúla"
                type="cedula"
                className="inputbox"
                value={formik.values.cedula}
                onChange={formik.handleChange}
                error={formik.touched.cedula && Boolean(formik.errors.cedula)}
                helperText={formik.touched.cedula && formik.errors.cedula}
                variant="outlined"
                />
              <TextField
                id="outlined-basic"
                name="edad"
                label="Escriba su edad"
                type=""
                className="inputbox"
                value={formik.values.edad}
                onChange={formik.handleChange}
                error={formik.touched.edad && Boolean(formik.errors.edad)}
                helperText={formik.touched.edad && formik.errors.edad}
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="phone"
                label="Teléfono de contacto"
                type=""
                className="inputbox"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="direccion"
                label="Dirección"
                type=""
                className="inputbox"
                value={formik.values.direccion}
                onChange={formik.handleChange}
                error={
                    formik.touched.direccion && Boolean(formik.errors.direccion)
                }
                helperText={formik.touched.direccion && formik.errors.direccion}
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="trabajo"
                label="Ocupación"
                type=""
                className="inputbox"
                value={formik.values.trabajo}
                onChange={formik.handleChange}
                error={formik.touched.trabajo && Boolean(formik.errors.trabajo)}
                helperText={formik.touched.trabajo && formik.errors.trabajo}
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="ingreso"
                label="Mensual"
                type=""
                className="inputbox"
                value={formik.values.ingreso}
                onChange={formik.handleChange}
                error={formik.touched.ingreso && Boolean(formik.errors.ingreso)}
                helperText={formik.touched.ingreso && formik.errors.ingreso}
                variant="outlined"
                />
              <TextField
                id="outlined-basic"
                name="estadoCivil"
                label="Estado Civil"
                type=""
                className="inputbox"
                value={formik.values.estadoCivil}
                onChange={formik.handleChange}
                error={
                    formik.touched.estadoCivil &&
                    Boolean(formik.errors.estadoCivil)
                }
                helperText={
                    formik.touched.estadoCivil && formik.errors.estadoCivil
                }
                variant="outlined"
                />

              <TextField
                id="outlined-basic"
                name="familiaN"
                label="Número de familiares en casa"
                type=""
                className="inputbox"
                value={formik.values.familiaN}
                onChange={formik.handleChange}
                error={
                    formik.touched.familiaN && Boolean(formik.errors.familiaN)
                }
                helperText={formik.touched.familiaN && formik.errors.familiaN}
                variant="outlined"
                />

              <Button
                id="btn"
                className="LK"
                style={{ color: "black", width: "100px" }}
                type="submit"
                >
                Adoptar
              </Button>
              <Link
                as={Button}
                to="/adopta"
                id="btn"
                className="LV"
                style={{ color: "black", width: "100px" }}
                type="submit"
                >
                Volver
              </Link>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Formulario;
