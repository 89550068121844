import "./App.css";
import Signup from "./components/Signupform";
import SignupAdmin from "./components/SignupformAdmin";
import Login from "./components/LoginForm";
import DashNavbar from "./components/Navbar/DashNavbar";
import Dashboard from "./components/Dashboard/Dashboard";
import MapView from "./components/Dashboard/MapView";
import Splashscreen from "./components/Splashscreen";
import Profile from "./components/Profile";
import SearchAppBar from "./components/Navbar/SearchAppBar";
import MyPosts from "./components/Dashboard/MyPosts";
import EditDesaparecido from "./components/Dashboard/EditDesaparecido";
import EditAdopta from "./components/Dashboard/EditAdopta";
import MyPost from "./components/Dashboard/MyPost";
import ForgetPassword from "./components/Forgetpassword/Forgetpassword";
import axios from "axios";
import HomePage from "../src/pages/HomePage";
import Denuncia from "../src/pages/Denuncias";
import Formulario from "../src/components/Formulario";
import Admin from "../src/components/Admin";
import Usuarios from "./components/Admin/Usuarios";
import DenunciasForm from "./components/Admin/Denuncias";
import Formularios from "./components/Admin/Formularios";
import Footer from "./pages/footer";
import Requisitos from "./pages/Requisitos";
import Pasos from "./pages/Pasos";
import { baseurl } from "./core";
import { useEffect } from "react";
import {
  Switch,
  useHistory,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { GlobalContext } from "./context/Context";
import { useContext } from "react";
import AdoptaPage from "../src/components/Dashboard/AdoptaPage";

function App() {
  let { state, dispatch } = useContext(GlobalContext);


  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log("res: ", res.data);

        if (res.data.email) {
          dispatch({
            type: "USER_LOGIN",
            payload: {
              name: res.data.name,
              email: res.data.email,
              role: res.data.role,
              phone: res.data.phone,
              _id: res.data._id,
            },
          });
        } else {
          dispatch({ type: "USER_LOGOUT" });
        }
      })
      .catch((e) => {
        dispatch({ type: "USER_LOGOUT" });
      });

    return () => {};
  }, []);

  return (
    <>
      {state?.user?.email ? <DashNavbar /> : <SearchAppBar />}

      {state.user === undefined ? (
        <Switch>
          <Route exact path="/homepage">
            <Route exact path="/signupformAdmin" component={SignupAdmin} />
            <Splashscreen />
          </Route>
        </Switch>
      ) : null}

      {state.user === null ? (
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/homepage" component={HomePage} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signupformAdmin" component={SignupAdmin} />
          <Route path="/forget_password" component={ForgetPassword} />
          <Route path="/requisitos" component={Requisitos} />
          <Route path="/pasos" component={Pasos} />
          <Redirect to="/homepage" />
        </Switch>
      ) : null}

      {state.user ? (
        <Switch>
          <Route exact path="/">
            <Profile path="/pull/:id"/>
          </Route>
          <Route exact path="/formulario/:_id">
            <Formulario />
          </Route>
          <Route exact path="/editar/:idPostDes">
            <EditDesaparecido />
          </Route>
          <Route exact path="/edita/:_id">
            <EditAdopta />
          </Route> 
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/map">
            <MapView />;
          </Route>
          <Route exact path="/admin/usuarios">
            <Usuarios />
          </Route>
          <Route exact path="/admin/formularios">
            <Formularios />
          </Route>
          <Route exact path="/admin/denuncias">
            <DenunciasForm />
          </Route>
          <Route exact path="/denuncias">
            <Denuncia />
          </Route>
          <Route path="/myposts">
            <MyPosts />
          </Route>
          <Route path="/mypost">
            <MyPost />
          </Route>
          <Route exact path="/homePage" component={HomePage} />
          <Route exact path="/adopta" component={AdoptaPage} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/requisitos" component={Requisitos} />
          <Route exact path="/signupformAdmin" component={SignupAdmin} />

          <Redirect to="/homepage" />
        </Switch>
      ) : null}

      {state?.user?.email ? <Footer /> : <Footer />}
    </>
  );
}

export default App;
