import * as React from "react";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { baseurl } from "../../core";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import "../../App.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function PostCard(props) {
  // let { state, dispatch } = useContext(GlobalContext);
  const [deletepost, setdeletepost] = useState({ eliminado: false });
  const {
    post,
    name,
    time,
    _id,
    imgUrl,
    raza,
    color,
    tamano,
    location,
    edad,
    descripcion,
    idPostDes,
  } = props;

  async function eliminar() {
    const resultado = await Swal.fire({
      title: "Confirmación",
      text: `¿Eliminar "${props.post}"?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3298dc",
      cancelButtonColor: "#f14668",
      cancelButtonText: "No",
      confirmButtonText: "Sí, eliminar",
    });
    //Si no confirma, detenemos la función
    if (!resultado.value) {
      return;
    }
    const respuesta = await axios.delete(`${baseurl}/api/v1/myposts/${_id}`, {
      withCredentials: true,
    });
    if (respuesta.data) {
      toast("Post eliminado ", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setdeletepost({
        eliminado: true,
      });
    } else {
      toast.error("Error eliminando. Intenta de nuevo");
    }
  }
  if (deletepost.eliminado) {
    return null;
  }

  return (
    <div className="postcard">
      <Card sx={{ maxWidth: "80%" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {name[0]}
            </Avatar>
          }
          // action={
          //     <IconButton aria-label="settings">
          //         <MoreVertIcon />
          //     </IconButton>
          // }

          id="post-name"
          title={name}
          subheader={time}

          //  id="post"
          //  title={email}
          //  subheader={time}
        />
        <hr style={{ width: "100%" }} />
        <CardContent>
          <div>
            <img
              style={{
                width: "200px",
              }}
              src={
                imgUrl
                  ? imgUrl
                  : "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
              }
              alt="Imagen"
            />
          </div>
          <Typography variant="body2" color="text.secondary" id="post-item">
            <b>Nombre:</b> {post} <b>{edad}</b> <br></br>
            <b>Raza:</b> {raza} <br></br>
            <b>Tamaño:</b> {tamano}
            <br></br>
            <b>Color:</b> {color}
            <br></br>
            <b>Última vez visto:</b> {location} <br></br>
            <b>Descripción: </b> {descripcion}
            <br></br>
            {/* {idPostDes} */}
          </Typography>
        </CardContent>

        {/* <CardActions>
          <div className="cardfooter">
            <IconButton className="cardicon" aria-label="Comment">
              <ModeCommentIcon style={{ marginRight: '40px' }} />
            </IconButton>            
          </div>
        </CardActions> */}
        <CardActions>

        <div className="cardfooter">
          <Link
            to={`/editar/${_id}`}
            className="LK"
            style={{
              borderRadius: "10px",
              borderColor: "black",
              padding: "15px",
              margin: "50px",
            }}
            >
            Editar
          </Link>

          <button
            style={{
              background: "#F3320B",
              background: "aqua",
              borderRadius: "10px",
              borderColor: "#00a8ff",
              color: "black",
              padding: "10px",
              margin: "5px",
            }}
            variant="contained"
            className="succes"
            onClick={eliminar}
            >
            Eliminar
          </button>
        </div>
          </CardActions>
      </Card>
    </div>
  );
}
