const Requisitos = () => {
    return(
        <>
            <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <section   className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}>
        <h2
          className="section-heading text-uppercase"
          style={{ color: "orange", borderColor: "white" }}
        > ¿Cómo adoptar un perro en línea?</h2>
        <p>
       <dv style={{color:"white"}}>

Unos pasos sencillos sirven de guía para adoptar de un modo seguro a un perro o un gato gracias a Internet. <br></br>
Acceder a un sitio web seguro donde adoptar a un perro. ...<br></br>
Visitar los perfiles de los animales que buscan hogar. ...<br></br>
Rellenar el formulario on line de adopción. ...<br></br>
Visitar el centro para recoger al perro o gato.<br></br>
       </dv>
        </p>
        </section>
        </>
        
    );
}

export default Requisitos;

