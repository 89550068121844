import { Button, MenuItem, TextField } from "@mui/material";
import axios from "axios"
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {baseurl} from "../../core"
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as yup from 'yup';
import moment from 'moment';


export default function EditAdopta (){

    const [post, setpost] = useState('');
    const [raza, setraza] = useState('');
    const [edad, setEdad] = useState('');
    const [color, setColor] = useState('');
    const [tamano, setTamano] = useState('');
    const [vacunas, setVacunas] = useState('');
    const [este, setEste] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [imageData, setImageData] = useState(null);
    
    const params=useParams();
    const validationSchema = yup.object({
        post: yup
          .string('Enter your password')
          .min(4, 'Name should be of minimum 4 characters length'),
      });
      
      const [item, setItem] = useState('');
      useEffect(()=>{
          let _id=params._id;
          axios.get(`${baseurl}/api/v1/mypost/${_id}`,{
              withCredentials: true
            }).then(res=>{
             const dataUsuario=res.data
          console.log(dataUsuario)
          setItem(res.data.post)
          setpost(res.data.post)
          setraza(res.data.raza)
          setColor(res.data.color)
          setEdad(res.data.edad)
          setTamano(res.data.tamano)
          setDescripcion(res.data.descripcion)
          setVacunas(res.data.vacunas)
          setEste(res.data.este)
          })
       },[])
           
      const submit = (values) => {
        console.log('values', values);
        let m = moment().format('MMMM Do YYYY');
        console.log(m);
        const _id =params._id;
        Swal.fire({
          title: 'Éxito',
          text: 'Anuncio editado correctamente',
          // icon: "success",
          button: 'Continuar',
          // showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          color: '#000000',
          width: 600,
          padding: '3em',
          background: '#fff url(/img/fondo2.jpg)',
          backdrop: `
    rgba(0,0,123,0.4)
    url("/img/perro.gif")
    left top
    no-repeat
    `,
        });
        axios
          .put(
            `${baseurl}/api/v1/mypost/${_id}`,{
              post: post,
              raza: raza,
              edad: edad,
              tamano: currency,
              color: color,
              vacunas:vacunas,
              este:este,
              descripcion: descripcion,
              imgUrl: imageData,
              time: m,
            },{
            
              withCredentials: true,
            }
          )
          .then((res) => {
            console.log("llega")
            Swal.fire({
              title: 'Éxito',
              text: 'Anuncio editado correctamente',
              // icon: "success",
              button: 'Continuar',
              // showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              color: '#000000',
              width: 600,
              padding: '3em',
              background: '#fff url(/img/fondo2.jpg)',
              backdrop: `
        rgba(0,0,123,0.4)
        url("/img/perro.gif")
        left top
        no-repeat
      `,
            });
            console.log('postdata', res.data);
            
          });
      };
      const currencies = [
        {
          value: 'Grande',
          label: 'Grande',
        },
        {
          value: 'Mediano',
          label: 'Mediano',
        },
        {
          value: 'Pequeño',
          label: 'Pequeño',
        },
      ];
      const [currency, setCurrency] = useState('undefined');
    
    
      const handleChange = (event) => {
        setCurrency(event.target.value);
      };
    
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            post: `${post}`,
            raza: '',
            edad: '',
            tamano: '',
            color: '',
            location: '',
            descripcion: '',
    
        },
        enableReinitialze: true,
        onSubmit: submit
      },
      );
    
    
     const [file, setFile] = useState(null);
    
     const sendHandler = () => {
       if (!file) {
         alert('you must upload file');
         return;
       }
    
       const formdata = new FormData();
       formdata.append('image', file);
    
       fetch('http:// localhost:9000/images/post', {
         method: 'POST',
         body: formdata,
       })
         .then((res) => res.text())
         .then((res) => console.log(res))
         .catch((err) => {
           console.error(err);
         });
    
       document.getElementById('fileinput').value = null;
    
       setFile(null);
     };
     const handleChangeImage = (e) => {
      const file = e.target.files[0] || e.dataTransfer.files[0];
      if (!file) return;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert('El archivo debe ser una imagen');
        return;
      }
      if (file.size > 5000000) {
        alert('El archivo debe ser menor a 5MB');
        return;
      }
      const reader = new FileReader();
      reader.onload = (er) => {
        setImageData(er.target.result);
      };
      reader.readAsDataURL(file);
    };
    
    return(
        <>
        <br></br>
    <h1>Editar</h1>
    <h1>{params._id}</h1>
    <p>{item}</p> 
    
    
    <div className="app-main">
            
              <form id="post-form" onSubmit={formik.handleSubmit}>
                <h3 style={{ padding: '5%' }}>Editar </h3>
                <TextField
                  id="outlined-basic"
                  name="post"
                  value = {post} 
                  onChange = {(e)=> { setpost( e.target.value ) } } 
                  style={{ padding: '3%' }}
                  label="Nombre"
                  type="post"
                  className="box"
                  // onChange={formik.handleChange}
                  error={formik.touched.post && Boolean(formik.errors.post)}
                  helperText={formik.touched.post && formik.errors.post}
                  variant="outlined"
                />
    
                <TextField
                  id="outlined-basic"
                  name="raza"
                 label="Raza"
                  type="raza"
                  className="box"
                  value={raza}
                  onChange = {(e)=> { setraza( e.target.value ) } } 
    
                  // onChange={formik.handleChange}
                  style={{ padding: '3%' }}
                  error={formik.touched.raza && Boolean(formik.errors.raza)}
                  helperText={formik.touched.raza && formik.errors.raza}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  name="edad"
                  label="Edad"
                  type="edad"
                  className="box"
                  style={{ padding: '3%' }}
                  value={edad}
                  onChange = {(e)=> { setEdad( e.target.value ) } } 
                  // onChange={formik.handleChange}
                  error={formik.touched.edad && Boolean(formik.errors.edad)}
                  helperText={formik.touched.edad && formik.errors.edad}
                  variant="outlined"
                />
    
                <TextField
                  id="outlined-basic"
                  name="tamano"
                  select
                  style={{ padding: '3%' }}
                  label="Tamaño"
                  value={currency}
                  onChange={handleChange}
                  className="box"
                  helperText="Seleccione un tamaño porfavor"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
    
                {/* error={formik.touched.tamano && Boolean(formik.errors.tamano)}
                                helperText={formik.touched.tamano && formik.errors.tamano}
                                
                                variant="outlined" /> */}
                <TextField
                  id="outlined-basic"
                  name="color"
                  label="Color"
                  type="colore"
                  style={{ padding: '3%' }}
                  className="box"
                  value={color}
                  onChange = {(e)=> { setColor( e.target.value ) } } 
                  // onChange={formik.handleChange}
                  error={formik.touched.color && Boolean(formik.errors.color)}
                  helperText={formik.touched.color && formik.errors.color}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  name="vacunas"
                  label="Vacunas"
                  type="vacunas"
                  className="box"
                  value={vacunas}
                  onChange = {(e)=> { setVacunas(e.target.value) } } 
                  // onChange={formik.handleChange}
                  style={{ padding: '3%' }}
                  error={formik.touched.vacunas && Boolean(formik.errors.vacunas)}
                  helperText={formik.touched.vacunas && formik.errors.vacunas}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  name="este"
                  label="Esterilización"
                  type="este"
                  className="box"
                  value={este}
                  onChange = {(e)=> { setEste(e.target.value) } } 
                  // onChange={formik.handleChange}
                  style={{ padding: '3%' }}
                  error={formik.touched.este && Boolean(formik.errors.este)}
                  helperText={formik.touched.este && formik.errors.este}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  name="descripcion"
                  style={{ padding: '3%' }}
                  label="Descripción"
                  type="descripcion"
                  className="box"
                  value={descripcion}
                  onChange = {(e)=> { setDescripcion(e.target.value)}} 
                  // onChange={formik.handleChange}
                  error={
                    formik.touched.descripcion && Boolean(formik.errors.descripcion)
                  }
                  helperText={
                    formik.touched.descripcion && formik.errors.descripcion
                  }
                  variant="outlined"
                />
    
                {/* <div>
                  <img
                    style={{
                      width: '150px',
                    }}
                    src={
                      imageData
                        ? imageData
                        : 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'
                    }
                    alt="Imagen"
                  />
                </div> */}
     <div>
    
    <img
      style={{ padding:"3%",
          width: '400px',
          verticalAlign: "center"
          
       }}
       src={
           item.imgUrl
           ? item.imgUrl
           : 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'
       }
       alt="Imagen"
       />
       </div>
                <div className="container mt-5">
                  <div className="cardp-3">
                    <div className="row">
                      <div className="col-10">
                        <input
                          id="fileinput"
                          onChange={handleChangeImage}
                          className="form-control"
                          // value={item.imgUrl}
                          type="file"
                        ></input>
                      </div>
                      <div className="col-2">
                        <button
                          onClick={sendHandler}
                          type="button"
                          className="btn btn-primary col-12"
                        >
                          Subir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  id="btn"
                  variant="contained"
                  bg="dark"
                  type="submit"
                  // action={formik.resetForm}
                  onChange={submit}
                  onClick={submit}
                >
                  Editar
                </Button>
              </form>
            </div>
        </>
    )    
}