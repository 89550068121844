import L from 'leaflet';

const myPin = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png';
const myPinICon =
  'https://upload.wikimedia.org/wikipedia/commons/e/ed/Map_pin_icon.svg';

export const VenueLocationIcon = L.icon({
  iconUrl: myPin,
  iconSize: [24, 41],
  iconAnchor: [0, 44],
  popupAnchor: [12, -40],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

export const MyLocationIcon = L.icon({
  iconUrl: myPinICon,
  iconSize: [26, 41],
  iconAnchor: [0, 44],
  popupAnchor: [12, -40],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});
