import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { baseurl } from "../core";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Profileimage from "../images/profile.jpg";
import "./../App.css";

function Profile() {
  // let { state, dispatch } = useContext(GlobalContext);

  const [profile, setProfile] = useState({});
  const [posts, setPosts] = useState([]);
  const [id, setId] = useState([]);
  const [deletepost, setdeletepost] = useState({ eliminado: false });
  const params = useParams();

  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log("res +++: ", res.data);
        setProfile(res.data);
        setId(res.data._id);
      });
    handleGetData();
  }, []);

  const handleGetData = () => {
    axios
      .get(`${baseurl}/formularios/email`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("post: ", response.data);
        setPosts(response.data);
      })
      .catch((err) => alert("Error in getting data"));
  };
  async function eliminar() {
    let _id = params._id;
    const resultado = await Swal.fire({
      title: "Confirmación",
      text: `¿Eliminar ""?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3298dc",
      cancelButtonColor: "#f14668",
      cancelButtonText: "No",
      confirmButtonText: "Sí, eliminar",
    });
    //Si no confirma, detenemos la función
    if (!resultado.value) {
      return;
    }
    const respuesta = await axios.delete(`${baseurl}/api/v1/profile/${_id}`, {
      withCredentials: true,
    });
    if (respuesta.data) {
      toast("Post eliminado ", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setdeletepost({
        eliminado: true,
      });
    } else {
      toast.error("Error eliminando. Intenta de nuevo");
    }
  }
  if (deletepost.eliminado) {
    return null;
  }

  return (
    <>
      <br></br>

      <section
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}
      >
        <h1 id="" className="section-heading text-uppercase">
          Mi Perfil
        </h1>
        <Container>
          <Row className="mt-4">
            <Col xs={12} className="text-center">
              <img
                src="https://thumbs.dreamstime.com/b/%C3%ADcono-de-perfil-avatar-predeterminado-del-usuario-medios-sociales-en-estilo-clipart-icono-252803905.jpg"
                alt="profile"
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Col>
            <h2
              className="section-heading text-uppercase"
              style={{ padding: "2%" }}
            >
              Datos Personales:
            </h2>
            <Col className="mt-4">
              <Card style={{ maxWidth: "360px" }} className="mx-auto p-4">
                <p className="text-center">
                  {/* <b>Nombre: </b> */}
                  <h4 className="h4">{profile?.name} </h4>
                </p>
                <p className="text-center">
                  <b>Correo: </b>
                  {profile?.email}
                </p>
                <p className="text-center">
                  <b>Teléfono: </b>
                  {profile?.phone}
                </p>
                <p className="text-center">
                  <b>Estado de Adopción: </b> <br></br>
                  <hr></hr>
                  {posts?.map((post, index) => {
                    return (
                      <div key={index}>
                        <b>
                          {" "}
                          <p>Adopción: {index + 1}</p>
                        </b>
                        <b>Fecha: </b> <p>{post.created}</p>
                        <b>
                          <p>Estado:</p>
                        </b>
                        <p>
                          {post.estado === "aprobado"
                            ? "Aprobado"
                            : "No aprobado"}
                        </p>
                      </div>
                    );
                  })}
                </p>

                <div style={{border:' 5px solid orange'}}>
                  <center >
                    En caso de aprobación el administrador se comunicara en
                    menos de 24 horas mediante whatsapp o llamada o comuniquese
                    con este número: <b>0968314340</b>
                  </center>
                </div>
                {/* <button
                  style={{
                    background: "#F3320B",
                    background: "aqua",
                    borderRadius: "10px",
                    borderColor: "#00a8ff",
                    color: "black",
                    padding: "10px",
                    margin: "5px",
                  }}
                  variant="contained"
                  className="succes"
                  onClick={eliminar}
                >
                  Eliminar
                </button> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Profile;
