import React, { useState, useEffect } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Markers from './VenueMarkers';
import axios from 'axios';
import { baseurl } from '../../core';

import 'leaflet/dist/leaflet.css';
import MarkerPopup from './MarkerPopup';

import { MyLocationIcon } from './VenueLocationIcon';
import Loading from '../utils/Loading';
import { Link } from 'react-router-dom';

const MapView = () => {
  const [state] = useState({
    zoom: 15,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 52.52437,
    lng: 13.41053,
  });// Ubicación ESPOCH

  const [myMarker, setMyMarker] = useState({});
  const [markers, setMarkers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const { latitude, longitude } = position.coords;

        setCurrentLocation({ lat: latitude, lng: longitude });
        const userMarker = {
          name: 'Mi ubicación',
          img:'Image',
          geometry: { lat: latitude, lng: longitude },
        };
        setMyMarker(userMarker);
        setIsLoading(false);

        loadMore();
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message);
      },
      {
        enableHighAccuracy: true,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    axios
      .get(`${baseurl}/api/v1/postdes`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data?.length) {
          const filterData = res.data.filter((item) => {
            return item.location;
          });

          console.log('Filter: ', filterData);

          const markers = filterData.map((item) => {
            const { location } = item;
            const array = location.split(',');

            const lat = array[0].replace(/\s/g, '');
            const lng = array[1].replace(/\s/g, '');

            return {
              name: item.post,
              img: item.imgUrl,
              geometry: { lat: lat, lng: lng },
            };
          });

          setMarkers(markers);

          console.log('res.data: ', filterData);
        }
      });
  };

  return (
    <section
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}
      >
    <div
      style={{
        marginTop: '100px',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
        }}
        className="section-heading text-uppercase"
      >
        Ubicaciones de mascotas desaparecidas
      </h1>
      {!isLoading && (
        <Map center={currentLocation} zoom={state.zoom}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={myMarker.geometry} icon={MyLocationIcon}>
            <MarkerPopup name={myMarker.name} />
            <MarkerPopup img={myMarker.img} />
          </Marker>
          <Markers venues={markers} />
        </Map>
      )}

      {isLoading && <Loading />}
      <Link className='LK' to="/dashboard">Volver</Link>
    </div>
    </section>
  );
};

export default MapView;
