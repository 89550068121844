import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import '../Dashboard/styles.css'
import '../../App.css'


export default function CardPro(props) {
    const { post, email,phone,recompensa, name, time,id, imgUrl,raza,color,tamano,edad, location,descripcion} = props;


    return (
        
    <div className="postcard">
    <Card sx={{ maxWidth: '80%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {name[0]}
          </Avatar>
        }
        // action={
        //     <IconButton aria-label="settings">
        //         <MoreVertIcon />
        //     </IconButton>
        // }

        id="post-name"
        title={name}
        subheader={time}

        //  id="post"
        //  title={email}
        //  subheader={time}
      />
      <hr style={{ width: '100%' }} />
      <CardContent>
        <div>
          <img
            style={{
              width: '200px',
            }}
            src={
              imgUrl
                ? imgUrl
                : 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'
            }
            alt="Imagen"
          />
        </div>
        <Typography variant="body2" color="text.secondary" id="post-item">
          
          <b>Nombre:</b> {post} <b>{edad}</b> <br></br>
                  <b>Raza:</b> {raza} <br></br>
                     <b>Tamaño:</b> {tamano}<br></br>
                     <b>Correo:</b> {email}<br></br>
                     <b>Teléfono de contacto:</b> {phone}<br></br>
                     <b>Color:</b> {color}<br></br>
                     <b>Recompensa: $</b> {recompensa}<br></br>
                     <b>Última vez visto:</b> {location} <br></br>
                     <b>Descripción: </b> {descripcion}<br></br>
                     {/* {idPostDes} */}
        </Typography>
      </CardContent>

      {/* <CardActions>
        <div className="cardfooter">
          <IconButton className="cardicon" aria-label="Comment">
            <ModeCommentIcon style={{ marginRight: '40px' }} />
          </IconButton>            
        </div>
      </CardActions> */}
    </Card>
    </div>
    );
}