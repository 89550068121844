import React from "react";


function Splashscreen() {
    return (
        <>
            <div className="splashscreen">
                <img src="https://c.tenor.com/sOcqo6-1sXQAAAAM/loading-bar.gif" alt="splashscreen" />
            </div>
        </>

    )
}

export default Splashscreen;