import '../../App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl } from '../../core';
import CardAD from './CardAD';


function MyPosts() {

    const [myposts, setMyposts] = useState([])


    useEffect(() => {
        axios.get(`${baseurl}/api/v1/mypost`,
            {
                withCredentials: true
            })
            .then(response => {
                console.log(response.data)
                setMyposts(response.data)
                console.log(myposts)
            })
            .catch(err => alert("Error in getting data"))
    }, [])



    return (
        <>
            <div className="app-main">
                <h1> Mis Publicaciones </h1>
                <div id="post">
                    {myposts?.map((posts, index) => (
                        <div className="postcard">
                            <CardAD
                                key={index}
                                _id={posts._id}
                                name={posts?.name}
                                raza={posts?.raza}
                                edad={posts?.edad}
                                este={posts?.este}
                                tamano={posts?.tamano}
                                vacunas={posts?.vacunas}
                                color={posts?.color}
                                descripcion={posts?.descripcion}
                                time={posts?.time}
                                post={posts?.post}
                                imgUrl={posts?.imgUrl}
                            />
                            
                            {/* <Button color="warning" onClick={deletePost(index)}>delete</Button> */}
                        </div>
                    )

                    )}
                </div>           
            </div>
        </>
    );
}

export default MyPosts;