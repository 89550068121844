import { Carousel, Container } from "react-bootstrap";
import "../css/styles.css";
import "../js/scripts";
import { Link, NavLink } from "react-router-dom";
import React from "react";
import { Button } from "@mui/material";


export default function HomePage() {
  return (
    <>
      <Container fluid className=" max-height:100%">
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100"
              style={{
                width: "50%",
              }}
              src="/portada2.jpeg"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>¡Adóptame hoy!</h3>
              <p>
                Este es el momento para que encuentres un amigo de 4 patas.
                Ingresa a Adoptar y mira a nuestros pequeños amigos.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 "
              src="/portada1.jpeg"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>¿Un nuevo miembro en la familia?</h3>
              <p>
                Adoptar una mascota es una enorma responsabilidad, ya que es un
                nuevo acompañante que podras tener a tu lado.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
      <section className="page-section" id="services">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">
              ¿Quieres adoptar una mascota?
            </h2>
            <h3
              className="section-subheading text-muted"
              style={{ padding: "2%" }}
            >
              Mascotame es un portal web el cual tiene el objetivo de ayudar a
              encontrar hogar a las mascotas que han sido excluidas de su hogar
              se encuentren en la calle. Para poder adoptar, publicar tu mascota
              desaparecida o dar en adopción, solo tienes que Registrate e
              iniciar Sesión.
            </h3>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-address-card fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">PROCESO DE ADOPCION</h4>
              <p className="text-muted">
                Si estas interesado en adoptar una mascota, procede a escoger a
                uno de ellos y enando el formulario.{" "}
              </p>

              <Link to="/pasos" as={NavLink} style={{whitespace: 'pre'}}>
                {" "}
                <i className="fa fa-caret-right"></i> Adopta{" "}
              </Link>
            
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-dog fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">PERDIDOS Y RESCATADOS</h4>
              <p className="text-muted">
                Si has visto alguna mascota extraviada por las calles, haznoslo
                saber! Ingresa los datos para ayudar a encontrar su hogar.
              </p>
              <Link to="/login" as={NavLink} style={{ textDecoration: 'none' }}>
                {" "}
                <i className="fa fa-caret-right"></i> Desaparecidos{" "}
              </Link>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-clipboard-check fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">REQUISITOS DE ADOPCION</h4>
              <p className="text-muted">
                Revisa aquí los requisitos que necesitas para poder adoptar
                cualquier mascota que elijas.
              </p>
              <Link to="/Requisitos" as={NavLink} style={{ textDecoration: 'none' }}>
                {" "}
                <i className="fa fa-caret-right"></i> Requisitos{" "}
              </Link>
              {/* <a className="btn btn-default btn-sm" href="#">
                            <i className="fa fa-caret-right"></i>Requisitos</a> */}
            </div>
          </div>
        </div>
      </section>

      <section
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
        }}
      >
        <h2
          className="section-heading text-uppercase"
          style={{ color: "orange", borderColor: "white" }}
        >
          <center>Perfil de la Fundación:</center>
        </h2>
        <center>
          <div className="container">
            <div className="text-center">
              <div
                style={{
                  // padding: "3%",
                  // marginLeft: "20%",
                  width: "100%",
                  // float:"right",
                }}
              >
                <div className="container py-10 text-wrap">
                  <h6
                    className="  text-center fs-8"
                    style={{ color: "#d1d1e0", fontFamily:"sans-serif" }}
                  >
                    <br></br>
                    <br></br>
                    
                    La fundación MASCOTAME, se fundó y legalizó en Riobamba el 3
                    de agosto de 2020 ante el Ministerio de Inclusión Social y
                    Económica bajo la figura original de asociación y
                    posteriormente reformada a fundación el 2 de marzo de 2021
                    con Acuerdo Ministerial # 4883, como una entidad apolítica,
                    arreligiosa, con personalidad jurídica, de derecho privado y
                    sin fines de lucro, fundada para la defensa y protección de
                    los animales.
                    <br></br>
                    <br></br>
                    Nuestro campo de acción se divide en “directa” y “divulgativa”.
                    <br></br>
                    <br></br>
                    La acción directa engloba tanto rescates de animales
                    víctimas de maltrato y abandono, como acciones legales
                    contra los autores de los delitos.
                    
                    <br></br>
                    Todos los animales que acogemos reciben la debida atención
                    veterinaria  (analíticas, tratamientos, cirugías, etc.) y son identificados y esterilizados antes de
                    emprender la búsqueda de adoptantes comprometidos a proporcionarles una vida sana y feliz.
                  </h6>
                </div>
              </div>
              <div>
                <br></br>
                <br></br>
                <br></br>
                <img
                  style={{
                    padding: "2%",
                    width: "50%",
                    // width: "100px",
                    // display: "inline-block",
                    // align: "left",
                  }}
                  className="img-fluid"
                  src="/orange.jpeg"
                  alt=""
                ></img>
              </div>
              
            </div>
          </div>
        </center>
      </section>
    </>
  );
}
