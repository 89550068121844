import 'bootstrap/dist/css/bootstrap.min.css';

import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

function SearchAppBar() {
    

    return (
        <>
            <Navbar bg="dark" variant='dark' expand="lg" fixed='top'>
                <Container>
                <Navbar.Brand as={NavLink} to="homepage" className="mt- 5">
            <Navbar bg="dark" variant="dark">
    <Container>
        <img
          alt=""
          src="/img/huee.jpg"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
            MASCOTAME
    </Container>
  </Navbar>

            </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">

                            <li>
                                <Link to="/signup" className="nav-itms">Registrate</Link>
                            </li>

                            <li>
                                
                                <Link to="/login" className="nav-itms">Iniciar Sesión</Link>
                            </li>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default SearchAppBar;