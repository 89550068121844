export default function footer() {
  return (
    <>
      <footer className="footer py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 text-lg-start">
              Copyright &copy; MASCOTAME 2023
            </div>
            <div className="col-lg-4 my-3 my-lg-0">
              <a className="btn btn-dark btn-social mx-2" href="https://www.facebook.com">
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
            <div className="col-lg-4 text-lg-end">
              <a className="link-dark text-decoration-none me-3" href="#!">
                Política de privacidad
              </a>
              <a className="link-dark text-decoration-none" href="#!">
                Terminos de Uso
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
