import axios from "axios";
import { useEffect, useState } from "react";
import { baseurl } from "../../core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Navbar from "./../Navbar";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Denuncias() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/denuncia`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setPosts(response.data);
      })
      .catch((err) => alert("Error in getting data"));
  }, []);

  async function exportDataToPdf() {
    var docDef = {
      pageSize: "A4",

      //pageOrientation: 'landscape',

      defaultStyle: {
        fontSize: 8,
        bold: false,
        border: 4,
      },

      content: [
        // margin: [left, top, right, bottom]
        {
          text: "Lista de Denuncias",
          alignment: "center",
          bold: true,
          fontSize: "16",
          margin: [0, 0, 0, 0],
        },

        // {
        //     image: await this.getBase64ImageFromURL("http://localhost:3000/images/employees.png")
        //  },

        {
          layout: "lightHorizontalLines",

          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*'],

            body: [
              [
                { text: "Nombres", bold: true },
                { text: "Cédula", bold: true },
                { text: "Agreción", bold: true },
                { text: "Lugar", bold: true },
                { text: "Descripción", bold: true },
              ],
              ...posts.map((post) => [
                post.apellido,
                post.cedula,
                post.tipo,
                post.lugar,
                post.descripcion,
              ]),
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDef).download();
  }

  // function getBase64ImageFromURL(url) {
  //     return new Promise((resolve, reject) => {
  //         var img = new Image();
  //         img.setAttribute("crossOrigin", "anonymous");

  //         img.onload = () => {
  //             var canvas = document.createElement("canvas");
  //             canvas.width = img.width;
  //             canvas.height = img.height;

  //             var ctx = canvas.getContext("2d");
  //             ctx.drawImage(img, 30, 30, 500, 500);

  //             var dataURL = canvas.toDataURL("image/png");

  //             resolve(dataURL);
  //         };

  //         img.onerror = error => {
  //             reject(error);
  //         };

  //         img.src = url;
  //     });
  // }

  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <Navbar />
      <main role="main" className="container">
        <h2 className="text-center mt-5 mb-3">Lista de Denuncias</h2>
        <div className="text-center">
          <button
           type="button"
           className="mb-3 btn"
           style={{
             backgroundColor: '#00a8ff',
             color: 'white',
           }}
            onClick={exportDataToPdf}
          >
            Exportar PDF
          </button>
        </div>
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Nombres</th>
              <th>Cédula</th>
              <th>Agreción</th>
              <th>Lugar</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post) => {
              return (
                <tr key={post?._id}>
                  <td>{post?.apellido}</td>
                  <td>{post?.cedula}</td>
                  <td>{post?.tipo}</td>
                  <td>{post?.lugar}</td>
                  <td>{post?.descripcion}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    </>
  );
}
