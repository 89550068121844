import "../App.css";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { useHistory } from 'react-router';
import axios from "axios";
import { baseurl } from "../core";

const submit = (values, { resetForm }) => {
  console.log("values", values);
  axios
    .post(`${baseurl}/api/v1/signup`, {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      role: values.role,
    })
    .then((res) => {
      console.log(res.data);
      alert("Usuario Auntenticado");
      resetForm({});
    });
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  email: yup
    .string("Ingrese un correo")
    .email("Ingrese un correo valido")
    .required("Correo no ingresado"),
  name: yup
    .string("Ingrese su nombre")
    .min(4, "Ingrese un nombre de usario mínimo 4 caracteres")
    .required("Name is required"),
  role: yup.string("Ingrese un rol").min(4, "").required("Rol is required"),

  phone: yup
    .string("Enter your phone no.")
    .matches(phoneRegExp, "Número de teléfono es incorrecto")
    .min(10, "Ingrese un número correcto")
    .max(10, "Ingrese un número correcto")
    .required("Número de teléfono requerido"),

  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

function Signupform() {
  let history = useHistory();
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      email: "",
      name: "",
      phone: "",
      password: "",
      role: "user",
    },
    onSubmit: submit,
  });

  return (
    <>
      <div className="app-main1">
        <div className="main1">
          <form onSubmit={formik.handleSubmit}>
            <h3> REGISTRO </h3>

            <TextField
              id="outlined-basic"
              name="name"
              label="name"
              className="inputbox"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              variant="outlined"
            />

            <TextField
              id="outlined-basic"
              name="email"
              label="email"
              className="inputbox"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="outlined"
            />

            <TextField
              id="outlined-basic"
              name="phone"
              label="Teléfono"
              className="inputbox"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              variant="outlined"
            />

            <TextField
              id="outlined-basic"
              name="password"
              label="Contraseña"
              type="password"
              className="inputbox"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              variant="outlined"
            />

            <center>
              <Button
                variant="contained"
                color="warning"
                type="submit"
                style={{ width: "50%" }}
              >
                Registrate
              </Button>
            </center>
          </form>
          <a style={{color:"black"}} onClick={() => { history.push("/login") }}>Si ya tienes una cuenta <b>Inicia Sesión</b></a>

        </div>
      </div>
    </>
  );
}

export default Signupform;
