import React from 'react';

export default function Loading() {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '70vh',
      }}
    >
      <img
        src="https://cdn.dribbble.com/users/1187836/screenshots/5684366/image.gif"
        alt="loading"
      />
    </div>
  );
}
