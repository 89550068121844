import './Admin.css';
import Formularios from './Admin/Formularios';


function Admin() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <Formularios isTitle={false} />
    </>
  );
}
export default Admin;
