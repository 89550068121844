import { Carousel, Container } from "react-bootstrap";

const Pasos = () => {
  return (
    <>
      <br></br>
      <br></br>
      
{/* 
      <Container fluid className=" max-height:100%">
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100"
              style={{
                width: "50%",
              }}
              src="https://img.freepik.com/vector-premium/perro-lindo-ilustracion-dibujos-animados-libro-concepto-estilo-dibujos-animados-plana-animal_75802-624.jpg?w=740"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>¡Adóptame hoy!</h3>
              <p>
                Este es el momento para que encuentres un amigo de 4 patas.
                Ingresa a Adoptar y mira a nuestros pequeños amigos.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 "
              src="/portada1.jpeg"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>¿Un nuevo miembro en la familia?</h3>
              <p>
                Adoptar una mascota es una enorma responsabilidad, ya que es un
                nuevo acompañante que podras tener a tu lado.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container> */}
      <section
        className="page-section"
        id="contact"
        style={{
          textAlign: "center",
          color: "whitesmoke",
          width:"100%"
        }}
      >
         <h2
          className="section-heading text-uppercase"
          style={{ color: "white", borderColor: "white" }}
        >PROCESO DE ADOPCION</h2>
        <div >
        <img className="img-fluid"
                style={{ width: "100",
                    height: "300px",
                    objectFit: "cover",
                    }}
                  src="/contorno.png"
                  alt=""></img>
        </div>
        <div className="container py-10 text-wrap">
          <h6 className="text-center fs-8">
            <h2
              className="section-heading text-uppercase"
              style={{ color: "orange", borderColor: "white" }}
            >
              NO ESTAS REGISTRADO{" "}
            </h2>
            <div style={{textAlign:"left"}}>
            1. Ingresar al portal web MASCOTAME<br></br>
            2. Dar clic en "registrarse"<br></br>
            3. Ingresar nomnbre, correo, teléfono y contraseña personal.
            <br></br>
            4. Dar clic en "registrar"<br></br>
            5. Apareceras en la pagina principal y dar clic en inicial sesión{" "}
            </div>
            <br></br>
            <br></br>
            <br></br>
            <h2
              className="section-heading text-uppercase"
              style={{ color: "orange", borderColor: "white" }}
            >
              ESTAS REGISTRADO{" "}
            </h2>
            <br></br>
            <div style={{textAlign:"left"}}>
            <br></br>
            1. Ingresar al portal web MASCOTAME<br></br>
            2. Dar clic en "registrarse"<br></br>
            3. Ingresar nomnbre, correo, teléfono y contraseñas personales
            <br></br>
            4. Dar clic en "registrar"<br></br>
            5. Apareceras en la pagina principal y dar clic en inicial sesión{" "}
            </div>
            <br></br>
            <h2
              className="section-heading text-uppercase"
              style={{ color: "orange", borderColor: "white" }}
            >
              INICIAR SESION 
            </h2>
            <div style={{textAlign:"left"}}>
            <br></br>
            1. Dar clic en iniciar sesión <br></br>
            2. Ingresar nombre y contraseña<br></br>
            3. Dar clic en "Iniciar"<br></br>
            </div>
            <br></br>
            <h2
              className="section-heading text-uppercase"
              style={{ color: "orange", borderColor: "white" }}
            >
              PROCESO PARA DAR EN ADOPCIÓN{" "}
            </h2>
            <div style={{textAlign:"left"}}>
            <br></br>
            1. En la parte superior de la barra de navegación dar clic en
            "Adopcion" <br></br>
            2. Ingresa la información requerida para dar en adopción <br></br>
            3. Dar clic en publicar "Publicar" <br></br>
            4. Ver la publicación de la mascota que das en adopción <br></br>
            </div>
            <br></br>
            <h2
              className="section-heading text-uppercase"
              style={{ color: "orange", borderColor: "white" }}
            >
              PROCESO PARA ADOPTAR{" "}
            </h2>
            <div style={{textAlign:"left"}}>
            <br></br>
            1. En la parte superior de la barra de navegación dar clic en
            "Adopcion" <br></br>
            2. Visualizar las mascotas que hay en adopción <br></br>
            3. Dar clic en "Adoptar" en la publicación de la mascota
            seleccionada<br></br>
            4. Ingresar los datos requeridos para la adopción <br></br>
            5. Dar clic en "Adoptar"<br></br>
            6. Esperar a que si petición sea aceptada por el administrador
            </div>
            <br></br>
            <br></br>
            <h2
              className="section-heading text-uppercase"
              style={{ color: "orange", borderColor: "white" }}
            >
              PROCESO PARA MASCOTA DESAPARECIDA
            </h2>
            <div style={{textAlign:"left"}}>
            <br></br>
            1. En la parte superior de la barra de navegación dar clic en
            "Desaparecido"<br></br>
            2. Ingresar la ubicación y los datos necesarios de la mascota
            desaparecida<br></br>
            3. Dar clic en "Publicar"<br></br>
            4. Visualizar la publicación de la mascota desaparecida<br></br>
            </div>
          </h6>
        </div>
      </section>
    </>
  );
};

export default Pasos;
