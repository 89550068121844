import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import '../Dashboard/styles.css'
import '../../App.css'
// import ModeCommentIcon from '@mui/icons-material/ModeComment';
// import { Button } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';


export default function PostCards(props) {
    const { post, email, name, time,_id, imgUrl,raza,color,tamano,vacunas,edad,este, descripcion} = props;

console.log(_id)
    return (
        <div className="postcard">
            <Card sx={{ maxWidth: "80%" }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {name[0]}
                        </Avatar>
                    }

                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon />
                    //     </IconButton>
                    // }


                    id="post-name"
                    title={name}
                    subheader={time}
                    
                    //  id="post"
                    //  title={email}
                    //  subheader={time}

                />
                <hr style={{width:"100%"}}/>
                <CardContent>
                  <div>
                    <img
                        style={{
                            width: '300px',
                        }}
                        src={
                            imgUrl
                                ? imgUrl
                                : 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'
                        }
                        alt="Imagen"
                />
          </div>
                    <Typography variant="body2" color="text.secondary" id="post-item">                        
                       <b>Nombre:</b> {post} <b>{edad}</b> <br></br>
                    <b>Raza:</b> {raza} <br></br>
                       <b>Tamaño:</b> {tamano}<br></br> 
                       <b>Color:</b> {color}<br></br>
                       <b>Vacunas:</b> {vacunas} <br></br>
                       <b>Esterelización:</b> {este} <br></br>
                       <b>Descripción: </b> {descripcion}
                       
                    </Typography>
                </CardContent>

                <CardActions>
                    <div className="cardfooter">    
                        <Link to={`/formulario/${_id}`} style={{width:"50%",textAlign:"center",left:"25%"}} className="LL">Adoptar</Link>
                        {/* <Link  className='LK' to="/formulario">Adoptar</Link>                        */}
                    </div>
                </CardActions>

            </Card>
        </div>
    );
}