import axios from "axios";
import { useEffect, useState } from "react";
import { baseurl } from "../../core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Navbar from "./../Navbar";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Usuarios() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseurl}/api/v1/signup`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setPosts(response.data);
      })
      .catch((err) => alert("Error in getting data"));
  }, []);

  async function exportDataToPdf() {
    var docDef = {
      pageSize: "A4",

      //pageOrientation: 'landscape',

      defaultStyle: {
        fontSize: 8,
        bold: false,
        border: 4,
      },

      content: [
        // margin: [left, top, right, bottom]
        {
          text: "Lista de usuarios",
          alignment: "center",
          bold: true,
          fontSize: "16",
          margin: [0, 0, 0, 10],
        },

        // {
        //     image: await this.getBase64ImageFromURL("http://localhost:3000/images/employees.png")
        //  },

        {
          layout: "lightHorizontalLines",

          table: {
            headerRows: 1,
            widths: ["*", "*", "*"],

            body: [
              [
                { text: "Nombre", bold: true },
                { text: "Correo", bold: true },
                { text: "Celular", bold: true },
              ],
              ...posts.map((post) => [
                post.name,
                post.email,
                "+593" + post.phone,
              ]),
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDef).download();
  }

  // function getBase64ImageFromURL(url) {
  //     return new Promise((resolve, reject) => {
  //         var img = new Image();
  //         img.setAttribute("crossOrigin", "anonymous");

  //         img.onload = () => {
  //             var canvas = document.createElement("canvas");
  //             canvas.width = img.width;
  //             canvas.height = img.height;

  //             var ctx = canvas.getContext("2d");
  //             ctx.drawImage(img, 30, 30, 500, 500);

  //             var dataURL = canvas.toDataURL("image/png");

  //             resolve(dataURL);
  //         };

  //         img.onerror = error => {
  //             reject(error);
  //         };

  //         img.src = url;
  //     });
  // }

  return (
    <>
      <br></br>
      <br></br>
      <br></br>

      <Navbar />
      <main role="main" className="container">
        <h2 className="text-center mt-5 mb-3">Lista de Usuarios</h2>
        <div className="text-center">
          <button
            type="button"
            className="mb-3 btn btn-primary"
            onClick={exportDataToPdf}
          >
            Exportar PDF
          </button>
        </div>
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Celular</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post) => {
              return (
                <tr key={post?._id}>
                  <td>{post?.name}</td>
                  <td>{post?.email}</td>
                  <td>+593{post?.phone}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    </>
  );
}
